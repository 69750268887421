#services img {
    padding: 1em;
    background: var(--dark3);
    -webkit-box-shadow: 5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.fullscreen{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 6em !important;
}
.mobile{
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    padding: 1em;
    margin: 1em 1em 2em;
}
@media screen and (max-width: 767.98px) {
    .fullscreen {
        display: none;
    }
    #services h2{
        padding-top: 0;
    }
}

#serviceCards{
    background-color: #1E1E1E;
}