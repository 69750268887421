#not-found h2 {
    padding-top: 0;
    margin-top: 1rem;
}

#not-found .title-font-primary {
    padding-top: 6rem;
}

#not-found img {
    padding-top: 3rem;

}

#not-found section {
    height: 100vh;
}

@media (min-width: 992px) {
    #not-found .row {
        padding-top: 8rem;
    }
}