#skills {
    padding-top: 6rem;
    padding-bottom: 8rem;
    background: var(--dark1);

}

/*element with primary background*/
#skills .list-group-item.active {
    color: #1E1E1E !important;
    background-color: var(--primary);
    border-color: var(--secondary);
}

#skills .list-group-item {
    color: var(--bright);
    background-color: var(--dark2);
    border: 1px solid var(--dark1);
}

#skills .list-group-item:hover {
    background-color: var(--dark3);
    color: var(--bright) !important;
    border: 1px solid var(--dark1);

}

#skills .list-group-item.active:hover {
    color: #1E1E1E !important;
    background-color: var(--primary);
    border-color: var(--secondary);
}

.img-skills {
    height: 4rem;
    width: auto;
    margin-right: 2rem;
    margin-bottom: 2rem;
}



#skills a {
}


@media screen and (max-width: 575.5px) {

  .tab-pane p{
      padding-top: 1em;
  }
    .img-div{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media (max-width: 575.98px) {

}