
.container-fluid {
    max-width: 1920px;
}

.portfolio-box {
    position: relative;
    display: block;
}

.portfolio-box-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    opacity: 0;
    color: var(--dark2);
    background: var(--primary);
    transition: opacity 0.25s ease;
}

.project-category {
    font-family: Nunito, sans-serif;
    font-size: 0.85rem;
    font-weight: 600;
    text-transform: uppercase;
}

.project-name {
    font-size: 1.2rem;
}

.portfolio-box-caption:hover {
    opacity: 1;
}

#portfolio .row {
    padding: 0;
    --bs-gutter-x: 0;
}

.banner {
    height: 15em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner img {

    margin-top: 3em;
    height: 10em;
}

.banner div{
    padding-left: 5em;
}

.banner div img {
    width:20em;
    height: auto;
    filter: invert(100%);
    padding-bottom: 1em;
}

@media screen and (max-width: 960px) {
    .banner {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top:2em;
        padding-bottom: 2em;
    }
    .banner div{
        padding-left: 0;
    }

}
