:root {
    /*colors*/
    --dark1: #121212;
    --dark2: #1E1E1E;
    --dark3: #2D2D2D;
    --primary:#64ffda;
    --secondary: #14cba8;
    --bright: #D0D0D0;
    --hover: #353535;
    --bs-body-color: var(--bright) !important;
    --bs-primary: var(--primary) !important;


}

/*
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  background: var(--dark1);

}*/
* {
    font-family: Poppins, sans-serif !important;
}

body {
    background: #121212 !important;
    color: var(--bright);
}

h1 {
    color: var(--primary);
    font-weight: 800 !important;
}

h2 {
    color: var(--bright);
    font-weight: 800;
    padding-top: 6rem;
    margin-top: 4rem;

}

h3 {
    text-transform: uppercase;
    color: var(--secondary);
}

h4 {
    color: var(--secondary);
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 800;
    padding-top: 1rem;
}



.color-primary {
    color: var(--primary)
}

.card-title {
    font-weight: 800;

}

#masthead h3 {
    padding-top: 6rem;
}

/*buttons*/
.btn-primary {
    background: var(--primary) !important;
    color: var(--dark1) !important;
    border-color: transparent !important;
}

.btn-primary:hover {
    background: var(--secondary) !important;
    color: var(--dark1) !important;
    border-color: transparent !important;
}

a {
    color: var(--bright) !important;
    text-decoration: none !important;

}

a:hover {
    color: var(--primary) !important;
}

.primary{
    color: var(--primary);
}