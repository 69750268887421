.name-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

#contact {
    padding-top: 0;
    padding-bottom: 6em;
}

.form-row {
    justify-content: center;
    display: flex;
}

form {
    background: var(--dark2);
    padding: 1em 2em;
    margin: -1em -2em;
    border-radius: 5px;

}

.untertitel {
    padding-bottom: 2em;
}

.fullwidth {
    width: 90%;
}

#contact .form-control:focus {
    color: var(--dark2);
    background-color: #fff;
    border-color: var(--primary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(100 225 218 / 25%);
}

.result-div {

}

.result-div p {
    margin-bottom: 0;
    margin-top: 1em;
    padding: 1em;
    color: var(--primary);
    background: var(--dark3);
    border-radius: 5px;
}

@media screen and (max-width: 960px) {
    form{
        margin: 0;
    }
}
