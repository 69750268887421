#navbar.navbar {
    background: var(--dark2);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    transition: background-color 0.2s ease;

}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1em;
    padding-left: 1em;
}

.navbar-logo {
    color: var(--bright);
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

#navbar .nav-item:hover {
    border-bottom: 2px solid var(--primary);
}

#navbar .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.7s ease;

    }

    .nav-menu.active {
        background:var(--dark2);
        left: 0;
        opacity: 1;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: var(--primary);
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    #navbar .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
        padding-bottom: 0;
    }
    .navbar-logo img{
        height: 3rem;
        margin-bottom: 0em !important;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--bright);
    }

    .fa-times {
        color: var(--bright);
        font-size: 2rem;
    }

    .nav-btn {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        height: 120px;
    }
    #donation-nav {
        color: var(--primary) !important;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
    }
    #navbar button{

    }
}

.navbar-logo img{
    height: 3rem;
    margin-bottom: 1em;
}

#donation-nav {
    display: flex;
    align-items: center


}
