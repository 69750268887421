#mh-img {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    #mh-img {
        padding-top: 1em;
    }
}
